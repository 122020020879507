/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    FormGroup,
    Label,
    Button,
    Modal,
    Row,
    Col
} from "reactstrap";

class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      miniModal: false,
      notificationModal: false,
      subscriptionModal: false,
      onEmail: false,
      onHandle: true,
      selectText: "Select Handle",
      buttonText: "Next >",
      buttonColor: "",
      notificationText: "",
      subscriptionText: "",
      notificationVisible: false,
      showHandleWarning: false,
      showGenericWarning: false,
      path: "",
      url: "https://api.handledrop.com/v1/{handle}/{email}/verify",
      follower: {
          handle: "",
          email: "",
      },
    };
  }

   componentDidMount() {
    const path = window.location.pathname;
    var pathAttr = path.split("/").filter(value => Object.keys(value).length !== 0);
    window.history.replaceState(null, null, window.location.origin);
    if (pathAttr.length == 4)  {
        const action = pathAttr[2];
        if (action.endsWith("subscribe")){
            this.toggleSubscriptionModal(action, path);
        }
     }
   };

  toggleSubscriptionModal = (action, path)  => {
    if (action.endsWith("subscribe")) {
        let msg = "";
        if (action==="subscribe"){
            msg = "If you agree, You will be subscribed to the requested handle and will get notified when it becomes avaiable."
        }
        else if (action === "unsubscribe") {
            msg = "If you unsubscribe, you will not be able to re-subscribe to the same handle for up to 24 hours."
        }
        else {
            window.history.replaceState(null, null, window.location.origin );
        }
        this.setState({
          subscriptionText: msg,
          path: path,
          subscriptionModal: !this.state.subscriptionModal
        });
     }
     else{
        if (action === "agree") {
            this.subscriptionHandler(path);
        }
        this.setState({
          subscriptionModal: !this.state.subscriptionModal
        });
        window.history.replaceState(null, null, window.location.origin );
     }
  };

  subscriptionHandler = () => {
    let self = this;
    const url = "https://api.handledrop.com/v1" + this.state.path.replace("@", "%40").replace("+", "%2B");
    var xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onreadystatechange = function() {
        if(xhr.readyState === 4){
           self.setState({
                notificationText: xhr.responseText,
                follower: {
                    "handle": "",
                    "email": ""
                }
            });
            self.toggleNotificationModal();
            self.toggleHandle();
            setTimeout(() => {
                window.history.replaceState(null, null, window.location.origin );
            }, 3000);
        }
    };
    xhr.send();
    xhr.onerror = (e) => console.log("ERROR" + e);
  }

   sendVerification = () => {
     let self = this;
     const url = this.state.url.replace("{handle}", this.state.follower.handle).replace("{email}", this.state.follower.email).replace("@", "%40").replace("+", "%2B");
     var xhr = new XMLHttpRequest();
     xhr.open("PUT", url, true);
     xhr.onreadystatechange = function() {
      if(xhr.readyState === 4){
        self.setState({
            notificationText: xhr.responseText,
            follower: {
                "handle": "",
                "email": ""
            }
        });
        self.toggleNotificationModal();
        self.toggleHandle();
      }
    };
    xhr.send();
  };


  toggleModal = modalState => {
    this.setState({
      ["miniModal"]: !this.state["miniModal"]
    });
    if (modalState == "agree") {
        this.sendVerification();
    }
  };


  toggleNotificationModal = () => {
      if (!this.state["notificationModal"]) {
       this.setState({
          notificationModal: true
        });
        setTimeout(() => {
          this.setState({
            notificationModal: false
           })
          }, 3000);
      }
      else {
        this.setState({
            notificationModal: false
        });
      }
  };


  toggleEmail = () => {
    if (this.state.buttonText == "Subscribe") {
        if (this.state.follower.handle.length > 4 && this.state.follower.email.length > 6) {
            this.toggleModal("miniModal");
        }
        else {
            this.setState({
                showGenericWarning: true
            });
        }
    }
    else if (this.state.follower.handle.length > 4 && this.state.follower.handle.length < 16){
        this.setState({
            showHandleWarning: false,
            onEmail: true,
            onHandle: false,
            buttonText: "Subscribe",
            buttonColor: "warning",
            selectText: "Insert Subscription Email"
        });
    }
    else {
        this.setState({
            showHandleWarning: true
        });
    }
  };


   toggleHandle = () => {
      this.setState({
        onEmail: false,
        showGenericWarning: false,
        onHandle: true,
        buttonText: "Next >",
        buttonColor: "",
        selectText: "Select Handle"
      });
  };


  keyPressed = (event)  => {
      if (event.key === "Enter") {
        this.toggleEmail();
      }
  }


  handleChange = input => event => {
    let follower = {...this.state.follower};
    follower[input] = event.target.value;
    this.setState({ follower: follower });
    if (event.target.value.length > 15) {
        this.setState({
            showHandleWarning: true
        });
    }
    if (event.target.value.length > 4 && event.target.value.length <= 15){
        this.setState({
            showHandleWarning: false
        });
    }
    //console.log(this.state.follower);
  };


  render() {
    return (
      <div className="page-header header-filter">
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="squares square7" />
        <Container>
          <div className="content-center brand">

            <img src="https://handledrop.com/logo_white.png" /><br/><br/>
            <h4 className="d-none d-sm-block">
              Get notified when your favorite handle becomes available!
            </h4><br/><br/><br/>
            <h3 className="d-none d-sm-block">{this.state.selectText}</h3>

            { /*Handle input text area */}
            { this.state.onHandle &&
             <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-globe-2" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                autofocus="true"
                onKeyPress={this.keyPressed}
                placeholder="Handle"
                type="text"
                value={this.state.follower.handle}
                onChange={this.handleChange('handle')}
                onFocus={e => this.setState({ emailFocus: true })}
                onBlur={e => this.setState({ emailFocus: false })}
              />
            </InputGroup> }

            { /* Email input text area */}
            { this.state.onEmail && <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                onKeyPress={this.keyPressed}
                placeholder="Email"
                type="email"
                value={this.state.follower.email}
                onChange={this.handleChange('email')}
                onFocus={e => this.setState({ emailFocus: true })}
                onBlur={e => this.setState({ emailFocus: false })}
              />
            </InputGroup> }
            {this.state.showHandleWarning &&  <p style={{ color: '#FF14BF' }} > Handle must be between 5 and 15 characters ( letters, numbers and '_' ) </p> }
            {this.state.showGenericWarning &&  <p style={{ color: '#FF14BF' }} > Please insert valid email and handle. </p> }
            <Row>
             <Col>
              <FormGroup check disabled>
                <Label check>
                  <Input defaultChecked disabled type="checkbox" />
                  <span className="form-check-sign" />
                  <a href="https://app.termly.io/document/terms-of-use-for-saas/c29c6269-c834-4354-bf42-99f081a38f68" target="_blank"><p color="white">I Agree to <br/>Terms of Service</p></a>
                </Label>
              </FormGroup>
            </Col>

            <Col>
            { this.state.onEmail &&
            <Button
                onClick={() => this.toggleHandle()}
              >
                &lt; Back
            </Button> }
             </Col>

              <Col>
            <Button
                color={this.state.buttonColor}
                onClick={() => this.toggleEmail()}
              >
                {this.state.buttonText}
            </Button>
             </Col>

             </Row>


            {/* subscribe modal */}
            <Modal
              modalClassName="modal-mini modal-primary modal-mini"
              isOpen={this.state.miniModal}
              toggle={() => this.toggleModal("miniModal")}
            >
              <div className="modal-header justify-content-center">
                <button
                  className="close"
                  onClick={() => this.toggleModal("miniModal")}
                >
                  <i className="tim-icons icon-simple-remove text-white" />
                </button>
                  <img src="https://handledrop.com/logo_small.png"/>
              </div>
              <div className="modal-body">
                <p>If you Agree, we will send you an email to complete the subscription.</p>
              </div>
              <div className="modal-footer">
                <Button className="btn-neutral"
                        onClick={() => this.toggleModal("cancel")}
                        color="link"
                        type="button">
                  Cancel
                </Button>
                <Button
                  className="btn-neutral"
                  color="link"
                  onClick={() => this.toggleModal("agree")}
                  type="button"
                >
                  Agree
                </Button>
              </div>
            </Modal>

            { /* Notification Modal */}
            <Modal
              modalClassName="modal-mini modal-primary modal-mini"
              isOpen={this.state.notificationModal}
            >

              <div className="modal-body">
                <p>{this.state.notificationText}</p>
              </div>
            </Modal>


            {/* handle subscription modal */}
            <Modal
              modalClassName="modal-mini modal-primary modal-mini"
              isOpen={this.state.subscriptionModal}
              toggle={() => this.toggleSubscriptionModal("cancel")}
            >
              <div className="modal-header justify-content-center">
                <button
                  className="close"
                  onClick={() => this.toggleSubscriptionModal("cancel")}
                >
                  <i className="tim-icons icon-simple-remove text-white" />
                </button>
                  <img src="https://handledrop.com/logo_small.png"/>
              </div>
              <div className="modal-body">
                <p>{this.state.subscriptionText}</p>
              </div>
              <div className="modal-footer">
                <Button className="btn-neutral"
                        onClick={() => this.toggleSubscriptionModal("cancel")}
                        color="link"
                        type="button">
                  Cancel
                </Button>
                <Button
                  className="btn-neutral"
                  color="link"
                  onClick={() => this.toggleSubscriptionModal("agree")}
                  type="button"
                >
                  Agree
                </Button>
              </div>
            </Modal>

          </div>
        </Container>
      </div>
    );
  }
}

export default PageHeader;
